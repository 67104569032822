import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';

import CarouselButton from '../CarouselButton/CarouselButton';

const CarouselComponent = ({ news, onClick, nbNews }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: nbNews,
    slidesToScroll: 1,
  };

  const htmlRender = (content) => {
    const __html = content;
    return { __html };
  };

  return (
    <Slider {...settings}>
      {news.map((newInfo, index) => {

        return (
          <div key={newInfo.id}>
            <div style={{display:'flex', 'justify-content':'center', height:'100%'}}>
              <div
                className={styles.ItemWrapper}
                style={
                  newInfo.image && newInfo.image.url &&
                  { backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${newInfo.image.url})` }
                }>
                {/*<div className={styles.TitleWrapper}>*/}
                <div className={styles.TitleWrapper} style={{ backgroundColor: newInfo.buttonColor }}>
                  <div
                    className={styles.ItemTitle}
                    dangerouslySetInnerHTML={htmlRender(newInfo.title)}
                  />
                  <div
                    className={styles.ItemSubTitle}
                    dangerouslySetInnerHTML={htmlRender(newInfo.subtitle)}
                  />
                </div>
                <div className={styles.ButtonWrapper}>
                  <CarouselButton
                    color={newInfo.textButtonColor}
                    bgColor={newInfo.buttonColor}
                    type={newInfo.type}
                    onClick={() => onClick(newInfo, index)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

CarouselComponent.propTypes = {
  news: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  nbNews: PropTypes.number.isRequired,
};

export default CarouselComponent;
